@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}


/* Hamburger Menu */
.hamburger{
    cursor:pointer;
    width:24px;
    height:24px;
    transition: all 0.25s;
    position:relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom{
    position:absolute;
    width:24px;
    height:2px;
    top:0;
    left:0;
    background:#fff;
    transform:rotate(0);
    transition: all 0.5s;
}

.hamburger-middle{
    transform: translateY(7px);
}

.hamburger-bottom{
    transform: translateY(14px);
}

.open{
    transform:rotate(90deg);
}

.open .hamburger-top{
    transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle{
    display:none;
}

.open .hamburger-bottom{
    transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

